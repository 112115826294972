// src/components/Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import PieChart from './PieChart';

const Dashboard = () => {
    const navigate = useNavigate();

    // Use React state to track authentication status

    // You can replace this function with your actual authentication logic
    const checkAuthentication = () => {
        // Replace this with your authentication logic (e.g., checking a token, session, or local storage)
        const token = localStorage.getItem('authToken'); // Example: Retrieve a token from local storage
        if (!token) {
            navigate('/login'); // Redirect to the login page
        }
    };

    // Call the authentication check function when the component mounts
    React.useEffect(() => {
        checkAuthentication();
    });
    React.useEffect(() => {
        document.title = 'The Feelings Wheel'; // Set your desired title
    })

    return (
        <div>
            <h2>Hello, Welcome to the Feelings Wheel!</h2>
            <div className="chart-container">
                <PieChart />
            </div>
        </div>
    );
};

export default Dashboard;
