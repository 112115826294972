import React, {useRef, useState} from 'react';
import 'chart.js/auto';
import {save} from './save';
import {Pie, getElementAtEvent} from 'react-chartjs-2';
import './PieChart.css';
import './utils';

const PieChart = () => {
    const [currentEmotion, setCurrentEmotion] = useState('Primary'); // Initial emotion
    const [selectedDetailedEmotion, setSelectedDetailedEmotion] = useState(null); // State to save detailed emotion
    const [selectedTertiaryEmotion, setSelectedTertiaryEmotion] = useState(null); // State to save tertiary emotion
    const emotionColors = {
        Primary: {
            Happy: "#FFD700",      // Yellow
            Sad: "#1E90FF",        // Dodger Blue
            Disgusted: "#800080",  // Purple
            Angry: "#FF4500",      // Red-Orange
            Fearful: "#808080",    // Gray
            Bad: "#FF0000",        // Red
            Surprised: "#32CD32"   // Lime Green
        },
        Disgusted: {
            Disapproving: "#9400D3",   // DarkViolet
            Disappointed: "#800080",   // Purple
            Awful: "#8B0000",          // DarkRed
            Repelled: "#FF69B4",       // HotPink
        },
        Disapproving: {
            Judgmental: "#6A5ACD",   // SlateBlue
            Embarrassed: "#CD853F"    // Peru
        },
        Disappointed: {
            Revolted: "#708090",   // SlateGray
            Appalled: "#C71585"    // MediumVioletRed
        },
        Awful: {
            Nauseated: "#6B8E23",   // OliveDrab
            Detestable: "#2F4F4F"   // DarkSlateGray
        },
        Repelled: {
            Horrified: "#FF4500",   // Red-Orange
            Hesitant: "#FFD700"     // Yellow
        },
        Sad: {
            Hurt: "#0000FF",           // Blue
            Depressed: "#1E90FF",      // DodgerBlue
            Guilty: "#8A2BE2",         // BlueViolet
            Despair: "#708090",        // SlateGray
            Vulnerable: "#FF6347",     // Tomato
            Lonely: "#4169E1",         // RoyalBlue
        },
        Hurt: {
            Dissapointed: "#800080",   // Purple
            Embarrassed: "#CD853F"     // Peru
        },
        Depressed: {
            Inferior: "#708090",       // SlateGray
            Empty: "#2F4F4F"           // DarkSlateGray
        },
        Guilty: {
            Remorseful: "#FF6347",     // Tomato
            Ashamed: "#CD853F"         // Peru
        },
        Despair: {
            Powerless: "#808080",   // Gray
            Grief: "#696969"        // DimGray
        },
        Vulnerable: {
            Victimised: "#800000",   // Maroon
            Fragile: "#FFD700"      // Gold
        },
        Lonely: {
            Isolated: "#A9A9A9",     // DarkGray
            Abandoned: "#8B4513"     // SaddleBrown
        },
        Happy: {
            Optimistic: "#FFD700",   // Gold
            Trusting: "#00CED1",     // DarkTurquoise
            Peaceful: "#20B2AA",     // LightSeaGreen
            Powerful: "#FF4500",     // Red-Orange
            Accepted: "#32CD32",     // LimeGreen
            Proud: "#FF1493",        // DeepPink
            Interested: "#FF8C00",   // DarkOrange
            Content: "#FFFF00",      // Yellow
            Playful: "#FF00FF"       // Magenta
        },
        Optimistic: {
            Hopeful: "#32CD32",      // LimeGreen
            Inspired: "#FFD700"      // Gold
        },
        Trusting: {
            Sensitive: "#20B2AA",    // LightSeaGreen
            Intimate: "#9932CC"      // DarkOrchid
        },
        Peaceful: {
            Loving: "#FF1493",       // DeepPink
            Thankful: "#FFD700"      // Gold
        },
        Powerful: {
            Creative: "#FFD700",     // Gold
            Courageous: "#FF4500"    // Red-Orange
        },
        Accepted: {
            Respected: "#32CD32",    // LimeGreen
            Valued: "#FFD700"        // Gold
        },
        Proud: {
            Confident: "#FFD700",    // Gold
            Successful: "#FF4500"    // Red-Orange
        },
        Interested: {
            Curious: "#FFD700",      // Gold
            Inquisitive: "#FF8C00"   // DarkOrange
        },
        Content: {
            Free: "#00CED1",         // DarkTurquoise
            Joyful: "#FFD700"        // Gold
        },
        Playful: {
            Aroused: "#FF6347",      // Tomato
            Cheeky: "#FFD700"        // Gold
        },
        Surprised: {
            Excited: "#FFA500",      // Orange
            Amazed: "#00FF00",       // Green
            Confused: "#00BFFF",     // DeepSkyBlue
            Startled: "#FF6347"      // Tomato
        },
        Excited: {
            Energetic: "#FFD700",    // Gold
            Eager: "#FF8C00"         // DarkOrange
        },
        Amazed: {
            Awe: "#FFFF00",          // Yellow
            Astonished: "#FFD700"    // Gold
        },
        Confused: {
            Perplexed: "#00BFFF",    // DeepSkyBlue
            Disillusioned: "#FFD700" // Gold
        },
        Startled: {
            Dismayed: "#FFD700",     // Gold
            Shocked: "#FF6347"       // Tomato
        },
        Bad: {
            Tired: "#808080",        // Gray
            Stressed: "#FF0000",     // Red
            Busy: "#8B4513",         // SaddleBrown
            Bored: "#A0522D"         // Sienna
        },
        Tired: {
            Unfocused: "#D2691E",    // Chocolate
            Sleepy: "#808080"        // Gray
        },
        Stressed: {
            "Out of control": "#D2691E",   // Chocolate
            Overwhelmed: "#FF0000"  // Red
        },
        Busy: {
            Rushed: "#D2691E",       // Chocolate
            Pressured: "#FF4500"     // Red-Orange
        },
        Bored: {
            Apathetic: "#808080",    // Gray
            Indifferent: "#D2691E"   // Chocolate
        },
        Fearful: {
            Scared: "#FF4500",       // Red-Orange
            Anxious: "#FF8C00",      // DarkOrange
            Insecure: "#FFA500",     // Orange
            Weak: "#D2691E",         // Chocolate
            Rejected: "#B22222",     // FireBrick
            Threatened: "#8B0000"    // DarkRed
        },
        Scared: {
            Helpless: "#D2691E",     // Chocolate
            Frightened: "#FF4500"    // Red-Orange
        },
        Anxious: {
            Worried: "#FF4500",      // Red-Orange
            Overwhelmed: "#D2691E"   // Chocolate
        },
        Insecure: {
            Inadequate: "#D2691E",   // Chocolate
            Inferior: "#FFA500"      // Orange
        },
        Weak: {
            Worthless: "#D2691E",    // Chocolate
            Insignificant: "#FFA500" // Orange
        },
        Rejected: {
            Excluded: "#D2691E",     // Chocolate
            Persecuted: "#B22222"    // FireBrick
        },
        Threatened: {
            Nervous: "#FFA500",      // Orange
            Exposed: "#B22222"       // FireBrick
        },
        Angry: {
            "Let down": "#800000",   // Maroon
            Humiliated: "#9932CC",   // DarkOrchid
            Bitter: "#8B008B",       // DarkMagenta
            Mad: "#FF0000",          // Red
            Aggressive: "#DC143C",   // Crimson
            Frustrated: "#FF4500",   // Red-Orange
            Distant: "#1E90FF",      // DodgerBlue
            Critical: "#FF4500"      // Red-Orange
        },
        "Let down": {
            Betrayed: "#800000",     // Maroon
            Disrespected: "#9932CC"  // DarkOrchid
        },
        Humiliated: {
            Ridiculed: "#9932CC",    // DarkOrchid
            Disrespected: "#800000"  // Maroon
        },
        Bitter: {
            Violated: "#8B008B",     // DarkMagenta
            Indignant: "#FF4500"     // Red-Orange
        },
        Mad: {
            Furious: "#FF0000",      // Red
            Jealous: "#8B008B"       // DarkMagenta
        },
        Aggressive: {
            Hostile: "#FF4500",      // Red-Orange
            Provoked: "#DC143C"      // Crimson
        },
        Frustrated: {
            Annoyed: "#FF4500",      // Red-Orange
            Infuriated: "#FF0000"    // Red
        },
        Distant: {
            Withdrawn: "#1E90FF",    // DodgerBlue
            Numb: "#808080"          // Gray
        },
        Critical: {
            Sceptical: "#FFFF00",   // Yellow
            Dismissive: "#FFA500"   // Orange
        }
    };
    const chartRef = useRef(null);
    const handleSegmentClick = (event, element) => {
        if (getElementAtEvent(chartRef.current, event)[0] === undefined)
            return;
        const clickedSegmentIndex = getElementAtEvent(chartRef.current, event)[0].index;

        if (clickedSegmentIndex !== undefined) {
            if (currentEmotion === 'Primary') {
                let newEmotion = 'Primary'; // Default to Primary emotion
                switch (clickedSegmentIndex) {
                    case 0:
                        newEmotion = 'Happy';
                        break;
                    case 1:
                        newEmotion = 'Sad';
                        break;
                    case 2:
                        newEmotion = 'Disgusted';
                        break;
                    case 3:
                        newEmotion = 'Angry';
                        break;
                    case 4:
                        newEmotion = 'Fearful';
                        break;
                    case 5:
                        newEmotion = 'Bad';
                        break;
                    case 6:
                        newEmotion = 'Surprised';
                        break;
                    default:
                        break;
                }
                setCurrentEmotion(newEmotion);
            } else if (selectedDetailedEmotion == null) {
                // Handle drill down to detailed emotions
                const detailedEmotionArray = emotionColors[currentEmotion];
                const detailedEmotion = Object.keys(detailedEmotionArray)[clickedSegmentIndex];
                setSelectedDetailedEmotion(detailedEmotion);
                console.log(`Clicked detailed emotion: ${detailedEmotion}`);
            } else if (selectedTertiaryEmotion == null) {
                // Handle drill down to tertiary emotions
                const detailedTertiaryArray = emotionColors[selectedDetailedEmotion];
                console.log(detailedTertiaryArray);
                console.log(`index ${clickedSegmentIndex}`);
                const tertiaryEmotion = Object.keys(detailedTertiaryArray)[clickedSegmentIndex];
                setSelectedTertiaryEmotion(tertiaryEmotion);
                console.log(`Clicked tertiary emotion: ${tertiaryEmotion}`);
            }
        }
    };

    const handleReset = () => {
        setCurrentEmotion('Primary');
        setSelectedDetailedEmotion(null);
        setSelectedTertiaryEmotion(null);
    };
    const handleSave = () => {
        save(currentEmotion, selectedDetailedEmotion, selectedTertiaryEmotion)
    };

    const data = {
        labels: currentEmotion === 'Primary'
            ? ['Happy', 'Sad', 'Disgusted', 'Angry', 'Fearful', 'Bad', 'Surprised']
            : selectedDetailedEmotion == null
                ? Object.keys(emotionColors[currentEmotion])
                : selectedTertiaryEmotion == null
                    ? Object.keys(emotionColors[selectedDetailedEmotion])
                    : [selectedTertiaryEmotion],
        datasets: [
            {
                backgroundColor: currentEmotion === 'Primary'
                    ? Object.values(emotionColors['Primary'])
                    : selectedDetailedEmotion == null
                        ? Object.values(emotionColors[currentEmotion])
                        : selectedTertiaryEmotion == null
                            ? Object.values(emotionColors[selectedDetailedEmotion])
                            : [emotionColors[selectedDetailedEmotion][selectedTertiaryEmotion]],
                data: Array(
                    currentEmotion === 'Primary'
                        ? Object.keys(emotionColors['Primary']).length
                        : selectedDetailedEmotion == null
                            ? Object.keys(emotionColors[currentEmotion]).length
                            : selectedTertiaryEmotion == null
                                ? Object.keys(emotionColors[selectedDetailedEmotion]).length
                                : 1
                ).fill(14)
            }
        ]
    };


    const config = {
        type: 'pie',
        data: data,
        options: {
            maintainAspectRatio: true,
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    // ... (your tooltip customization)
                },
            },
        },
    };

    return (
        <div className="chart-container">
            <Pie data={data} options={config.options} ref={chartRef} onClick={handleSegmentClick}/>
            <div>
                {currentEmotion && currentEmotion !== 'Primary' && (
                    <p>Selected Primary Emotion: {currentEmotion}</p>
                )}
                {selectedDetailedEmotion && (
                    <p>Selected Detailed Emotion: {selectedDetailedEmotion}</p>
                )}
                {selectedTertiaryEmotion && (
                    <p>Selected Tertiary Emotion: {selectedTertiaryEmotion}</p>
                )}
                <button onClick={handleReset}>Reset</button>
                {selectedTertiaryEmotion && (
                    <div>
                        <span></span>
                        <button onClick={handleSave}>Save</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PieChart;
