// src/save.js
export const save = (currentEmotion, selectedDetailedEmotion, selectedTertiaryEmotion) => {
    const apiUrl = '/save'; // Replace with your API endpoint URL
    const timestamp = new Date().toISOString();
    const data = {
        currentEmotion,
        selectedDetailedEmotion,
        selectedTertiaryEmotion,
        timestamp
    };

    fetch(apiUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            if (response.ok) {
                alert(`Successfully saved ${currentEmotion}, ${selectedDetailedEmotion}, ${selectedTertiaryEmotion}`);
            } else {
                alert(`Failed to save data. Status code: ${response.status}`);
            }
        })
        .catch(error => {
            alert(`An error occurred while saving data: ${error}`);
        });
};
