// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const redirectToLogin = () => {
        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }
        return null; // Return null if the user is authenticated
    };
    const checkAuthentication = () => {
        // Replace this with your authentication logic (e.g., checking a token, session, or local storage)
        const token = localStorage.getItem('authToken'); // Example: Retrieve a token from local storage

        if (token) {
            setIsAuthenticated(true); // User is authenticated
        } else {
            setIsAuthenticated(false); // User is not authenticated
        }
    };

    // Call the authentication check function when the component mounts
    useEffect(() => {
        checkAuthentication();
    }, []);

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="*" element={redirectToLogin()} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
