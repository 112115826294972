// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate } from './auth'; // Import the authentication function
import './Login.css'; // Import your custom CSS file for styling

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    function generateUUIDv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }
    const handleLogin = () => {
        const isAuthenticated = authenticate(username, password);
        if (isAuthenticated) {
            const token = generateUUIDv4();
            localStorage.setItem('authToken', token)
            navigate('/dashboard'); // Redirect after successful login
        } else {
            setError('Invalid username or password');
        }
    };

    React.useEffect(() => {
        document.title = 'The Feelings Wheel'; // Set your desired title
    })

    return (
        <div className="login-container">
            <h2>Login</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className="input-container">
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button className="login-button" onClick={handleLogin}>Login</button>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Login;
